import React from 'react';
import './about.css';
import ME from '../../assets/me2.png';
import { FaAward } from 'react-icons/fa';
import { FiUsers } from 'react-icons/fi';
import { VscFolderLibrary } from 'react-icons/vsc';

const About = () => {
  return (
    <section id='about'>
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={ME} alt="About Me" />
          </div>
        </div>

        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Computer Programming</h5>
              <small>Georgian College</small>
            </article>

            <article className='about__card'>
              <FiUsers className='about__icon' />
              <h5>Programming Bootcamp</h5>
              <small>Rocketseat</small>
            </article>

            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Software Engineering</h5>
              <small>Positivo University</small>
            </article>
          </div>

          <p>
            3+ years of experience learning and working as a Software Engineer, establishing Full stack capabilities mainly with JavaScript and frameworks. 2 internships completed plus ongoing work as an IT volunteer. Educational prowess through Programming Bootcamp and College.
          </p>

          <a href="#contact" className='btn btn-primary'>Let's Talk</a>
        </div>
      </div>
    </section>
  );
};

export default About;