import React from 'react';
import './portfolio.css';
import IMG1 from '../../assets/dtmoney1.png';
import IMG2 from '../../assets/portfolio_complete.png';
import IMG3 from '../../assets/ignews.png';
import IMG4 from '../../assets/gobarber.png';
import IMG5 from '../../assets/ESports.jpeg';
import IMG6 from '../../assets/natrave.png';

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'Dt_Money - Transactions Dashboard',
    github: 'https://github.com/AndreMoraesRau/Dt_Money',
    demo: 'https://dt-money-two.vercel.app/'
  },
  {
    id: 2,
    image: IMG2,
    title: 'Portfolio Website',
    github: 'https://github.com/AndreMoraesRau/portfolio_website',
    demo: 'https://andremoraesrau.com'
  },
  {
    id: 3,
    image: IMG3,
    title: 'IgNews - Blog Newsletter',
    github: 'https://github.com/AndreMoraesRau/igNews',
    demo: 'https://ig-news-theta.vercel.app/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'GoBarber - Barber Shop Booking (In Progress)',
    github: 'https://github.com/AndreMoraesRau/GoBarber_RocketSeat',
    demo: '#'
  },
  {
    id: 5,
    image: IMG5,
    title: 'ESports - Group finder',
    github: 'https://github.com/AndreMoraesRau/ESports',
    demo: 'https://nlw-esports-ignite.vercel.app/'
  },
  {
    id: 6,
    image: IMG6,
    title: 'NaTrave - Soccer Predictions',
    github: 'https://github.com/AndreMoraesRau/natrave',
    demo: 'https://natrave-rho.vercel.app'
  },
];

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">

        {
          data.map(({ id, image, title, github, demo }) => {
            return (
              <article key={id} className='portfolio__item'>
                <div className='portfolio__item-image'>
                  <img src={image} alt={title} />
                </div>
                <h3>{title}</h3>
                <div className='portfolio__item-cta'>
                  <a href={github} className='btn' target='__blank'>Github</a>
                  <a href={demo} className='btn btn-primary' target='__blank'>Live Demo</a>
                </div>
              </article>
            );
          })
        }


      </div>
    </section>
  );
};

export default Portfolio;