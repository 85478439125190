/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './footer.css';
import { BsLinkedin } from 'react-icons/bs';
import { FiInstagram } from 'react-icons/fi';
import { FaGithub } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer>
      <a href="#" className='footer__logo'>Andre Moraes Rau</a>

      <ul className='permalinks'>
        <li><a href="#">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#experience">Experience</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#portfolio">Portfolio</a></li>
        {/* <li><a href="#testimonials">Testimonials</a></li> */}
        <li><a href="#contact">Contact</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.linkedin.com/in/andre-rau/" target="__blank"><BsLinkedin /></a>
        <a href="https://github.com/AndreMoraesRau" target="__blank"><FaGithub /></a>
        <a href="https://www.instagram.com/andre.m.rau/" target="__blank"><FiInstagram /></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; Andre Moraes Rau. All rights reserved.</small>
      </div>
    </footer>
  );
};

export default Footer;