import React from 'react';
import './services.css';
import { BiCheck } from 'react-icons/bi';

const Services = () => {
  return (
    <section id='services'>
      <h5>What I Offer</h5>
      <h2>Services</h2>

      <div className="container services__container">

        {/* DEVELOPER SKILLS */}
        <article className="service">
          <div className="service__head">
            <h3>Developer Skills</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Full applications development</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>HTML and CSS implementation</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Modern languages and technologies</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>React, TypeScript, Node, React Native, Prisma, SQL, SASS, Tailwind</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Backend structure</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Design and communication with Figma, Jira and Slack</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Git and Github for version control</p>
            </li>
          </ul>
        </article>

        {/* General Skills */}
        <article className="service">
          <div className="service__head">
            <h3>General Skills</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Office tools</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Adobe Premiere, Adobe Photoshop and Illustrator, Reaper, Blender</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Independent - self-sufficient</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Teamwork - Problem solver</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Detail oriented</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Time and task management</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Communication and Negotiation experience</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Decision making</p>
            </li>
          </ul>
        </article>

        {/* VOLUNTEER WORK AND CERTIFICATES */}
        <article className="service">
          <div className="service__head">
            <h3>Volunteer Work and Certificates</h3>
          </div>

          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>IT for Toronto Spiritist Society</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Digital Art and Design for Nilo Ricar</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Professional Scrum Master I</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Health and Safety certified by Ontario</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Communication, negotiation and persuasion diploma</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>Social volunteer for 'Casa do Caminho', 'TETO', and 'MONACI' [Brazil]</p>
            </li>
          </ul>
        </article>
      </div>
    </section>
  );
};

export default Services;